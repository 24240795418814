import * as mutationTypes from './mutation-types'
import i18n from 'common/js/i18n'
import { isEmpty } from 'common/js/global-utils'

export default {
    [mutationTypes.SET_ACCOUNT_NAV] (state, accountNav) {
        const formattedAccountNav = []
        if (!isEmpty(accountNav)) {
            accountNav.forEach(accountNavGroup => {
                const group = {}
                if (accountNavGroup.label) {
                    group.label = i18n.t(accountNavGroup.label)
                }
                group.links = []
                if (!isEmpty(accountNavGroup.links)) {
                    accountNavGroup.links.forEach(accountNavGroupLink => {
                        const link = {}
                        link.icon = accountNavGroupLink.icon ?? ''
                        link.label = i18n.t(accountNavGroupLink.label)
                        if (!isEmpty(accountNavGroupLink.toName)) {
                            link.internalLink = `${state.domain}/${i18n.locale}/${i18n.t(accountNavGroupLink.toName)}`
                        } else if (!isEmpty(accountNavGroupLink.externalLink)) {
                            link.externalLink = accountNavGroupLink.externalLink
                        } else if (!isEmpty(accountNavGroupLink.eventToTrigger)) {
                            link.eventToTrigger = accountNavGroupLink.eventToTrigger
                        }
                        group.links.push(link)
                    })
                }
                formattedAccountNav.push(group)
            })
        }
        state.accountNav = formattedAccountNav
    },
    [mutationTypes.SET_AUTHENTICATED] (state, authenticated) {
        state.authenticated = authenticated
    },
    [mutationTypes.SET_DOMAIN] (state, domain) {
        state.domain = domain
    },
    [mutationTypes.SET_INITIALS] (state, initials) {
        state.initials = initials
    },
    [mutationTypes.SET_USER_INFOS] (state, userInfos) {
        state.userInfos = userInfos
    }
}
