<template>
    <div class="HeaderAccount">
        <button
            :aria-label="getToggleButtonAriaText"
            :class="authenticated ? 'HeaderAccount-toggleButton--loggedIn' : ''"
            :title="getToggleButtonAriaText"
            class="HeaderAccount-toggleButton"
            @click="toggle"
        >
            <span class="HeaderAccount-toggleButtonIcon IconAccount--outlined" />
            <span class="HeaderAccount-toggleButtonText">{{ getToggleButtonText }}</span>
        </button>
        <v-dialog
            v-if="isCurrentBreakpointPhone"
            v-model="showAccountNav"
            width="auto"
            fullscreen
        >
            <v-card>
                <v-toolbar elevation="0">
                    <v-toolbar-title class="font">
                        {{ getLocale('account:account:title') }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn
                        :aria-label="getLocale('button:close')"
                        :title="getLocale('button:close')"
                        icon
                        dark
                        color="black"
                        @click="hideAccountNavContent"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-actions>
                    <account-nav
                        :accountNav="accountNav"
                        class="HeaderAccount-fullScreenModalNav"
                    />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <header-account-nav-content
            v-else
            v-show="getShowAccountNav"
            v-click-outside="clickOutside"
            class="HeaderAccount-content"
        />
    </div>
</template>

<script>
import { isOrContainsTarget } from 'common/js/global-utils'
import { mapState } from 'vuex'
import AccountNav from './AccountNav'
import HeaderAccountNavContent from './HeaderAccountNavContent'
import * as breakpoints from 'vuejs-common/js/global-breakpoints'

export default {
    components: {
        AccountNav,
        HeaderAccountNavContent
    },
    data () {
        return {
            showAccountNav: false
        }
    },
    computed: {
        ...mapState({
            accountNav: state => state.accountNav.accountNav,
            authenticated: state => state.accountNav.authenticated
        }),
        getShowAccountNav () {
            return this.showAccountNav
        },
        getToggleButtonText () {
            return this.authenticated ? this.getLocale('account:account:title') : this.getLocale('account:nav:offline_toggle')
        },
        getToggleButtonAriaText () {
            return this.authenticated ? this.getLocale('account:nav:toggle') : this.getLocale('account:nav:offline_toggle')
        },
        isCurrentBreakpointPhone () {
            return this.$_globalMixin_currentBreakpoint === breakpoints.BREAKPOINT_PHONE
        }
    },
    methods: {
        clickOutside (event) {
            if (!isOrContainsTarget(event.target, this.$el.querySelector('.HeaderAccount-toggleButton')) && !isOrContainsTarget(event.target, this.$el.querySelector('.HeaderAccount-content'))) {
                this.hideAccountNavContent()
            }
        },
        setAccountNavContentOffset () {
            const headerAccount = document.querySelector('.HeaderAccount')
            const button = document.querySelector('.HeaderAccount-toggleButton')
            const buttonIcon = document.querySelector('.HeaderAccount-toggleButtonIcon')
            const headerAccountHeight = headerAccount.getBoundingClientRect().height
            const buttonStyle = button.currentStyle || window.getComputedStyle(button)
            const buttonPaddingLeft = parseInt(buttonStyle.paddingLeft)
            const headerAccountWidth = headerAccount.getBoundingClientRect().width
            const buttonIconWidth = buttonIcon.getBoundingClientRect().width
            const nav = document.querySelector('.HeaderAccount-content')
            if (this.$_globalMixin_currentBreakpoint === breakpoints.BREAKPOINT_DESKTOP) {
                nav.style.right = (headerAccountWidth - buttonPaddingLeft - buttonIconWidth / 2 - 19) + 'px'
            } else {
                nav.style.right = '8px'
            }
            nav.style.top = headerAccountHeight + 'px'
        },
        hideAccountNavContent () {
            this.showAccountNav = false
        },
        logIn () {
            document.body.dispatchEvent(new CustomEvent('kas-login', { detail: {} }))
        },
        toggle () {
            if (this.authenticated) {
                this.updateAccountNavContentDisplay()
            } else {
                this.logIn()
            }
        },
        updateAccountNavContentDisplay () {
            if (!this.showAccountNav && this.$_globalMixin_currentBreakpoint !== breakpoints.BREAKPOINT_PHONE) {
                this.setAccountNavContentOffset()
            }
            this.showAccountNav = !this.showAccountNav
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'mainScss';
@import 'globalScss';

.HeaderAccount {
    position: relative;
    height: 68px;

    @media (min-width: $desktop-breakpoint) {
        height: 86px;
    }

    &-toggleButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        position: relative;
        min-width: 55px;
        height: 100%;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        color: $color-lighterText;
        transition: all .3s;

        &:hover,
        &:active,
        &:focus {
            color: $color-lightText;
        }

        @media (min-width: $desktop-breakpoint) {
            padding: 0 20px 0 15px;
        }

        &Icon {
            position: relative;
            font-size: 28px;
        }

        &Text {
            display: none;

            @media (min-width: $desktop-breakpoint) {
                display: inline;
            }
        }

        &--loggedIn {
            .HeaderAccount-toggleButtonIcon::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 6px;
                height: 6px;
                border-radius: 6px;
                background: $color-brandSecondary;
            }
        }
    }

    &-content {
        position: absolute;
    }
}
</style>
