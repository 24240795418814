import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '../account-nav/assets/scss/includes/_variables.scss'
import config from 'config'

Vue.use(Vuetify)

const opts = {
    theme: {
        dark: false,
        light: true,
        themes: {
            light: {
                error: config.theme.color_danger,
                primary: config.theme.primary_color,
                secondary: config.theme.color_brand_secondary,
                info: config.theme.color_info,
                success: config.theme.color_success,
                warning: config.theme.color_warning,
                lightGray3: config.theme.color_lightgray3
            }
        }
    }
}

export default new Vuetify(opts)
