import Vue from 'vue'
import i18n from 'common/js/i18n'
import router from './router'
import { globalMixin } from 'common/js/global-mixin'
import store from './store/store'
import vuetify from '@/plugin/vuetify'
import clickOutside from 'common/js/vue-directive-clickOutside'
import AccountNavWidget from './components/AccountNavWidget'
import vueCustomElement from 'vue-custom-element'

Vue.mixin(globalMixin)
Vue.directive('click-outside', clickOutside)
Vue.use(vueCustomElement)
AccountNavWidget.vuetify = vuetify
AccountNavWidget.store = store
AccountNavWidget.router = router
AccountNavWidget.i18n = i18n
Vue.customElement('account-nav-component', AccountNavWidget)
