import { render, staticRenderFns } from "./AccountNav.vue?vue&type=template&id=46f64d28&scoped=true"
import script from "./AccountNav.vue?vue&type=script&lang=js"
export * from "./AccountNav.vue?vue&type=script&lang=js"
import style0 from "./AccountNav.vue?vue&type=style&index=0&id=46f64d28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../usr/local/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46f64d28",
  null
  
)

export default component.exports