import mutations from './mutations'

export default {
    namespaced: true,
    mutations,
    state: {
        accountNav: [],
        authenticated: false,
        domain: '',
        initials: ''
    }
}
