<template>
    <component
        :is="'li'"
        :class="[isActive ? 'AccountNavItem--active' : '']"
        class="AccountNavItem"
    >
        <router-link
            v-if="to !== null"
            :to="to"
            class="AccountNavItem-link"
        >
            <icomoon-icon
                v-bind="[getIconProperties]"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
        </router-link>
        <a
            v-else-if="externalLink !== ''"
            :href="externalLink"
            class="AccountNavItem-link"
            target="_blank"
        >
            <icomoon-icon
                v-bind="[getIconProperties]"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
        </a>
        <a
            v-else-if="internalLink !== ''"
            :href="internalLink"
            class="AccountNavItem-link"
        >
            <icomoon-icon
                v-bind="[getIconProperties]"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
        </a>
        <button
            v-else-if="onClick !== null"
            class="AccountNavItem-link"
            @click="onClick"
        >
            <icomoon-icon
                v-bind="[getIconProperties]"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
        </button>
        <button
            v-else-if="getEventToTrigger !== null"
            class="AccountNavItem-link"
            @click="triggerEvent"
        >
            <icomoon-icon
                v-bind="[getIconProperties]"
                class="AccountNavItem-itemIcon"
            />
            {{ label }}
        </button>
    </component>
</template>

<script>
import IcomoonIcon from './common/IcomoonIcon'
import { isEmpty } from 'common/js/global-utils'

export default {
    components: {
        IcomoonIcon
    },
    props: {
        eventToTrigger: {
            type: [Object, String],
            default: null
        },
        externalLink: {
            type: String,
            default: ''
        },
        internalLink: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            required: true
        },
        iconClassPrefix: {
            type: String,
            default: 'Icon'
        },
        iconLayersCount: {
            type: Number,
            default: 0
        },
        isActive: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        onClick: {
            type: Function,
            default: null
        },
        to: {
            type: Object,
            default: null
        }
    },
    computed: {
        getEventToTrigger () {
            if (!isEmpty(this.eventToTrigger)) {
                if (typeof this.eventToTrigger === 'string') {
                    return {
                        name: this.eventToTrigger
                    }
                } else if (typeof this.eventToTrigger === 'object') {
                    return this.eventToTrigger
                }
            }
            return null
        },
        getIconProperties () {
            return {
                iconClassPrefix: this.iconClassPrefix,
                layersCount: this.iconLayersCount,
                name: this.icon
            }
        }
    },
    methods: {
        triggerEvent () {
            document.body.dispatchEvent(new CustomEvent(this.getEventToTrigger.name, { detail: this.getEventToTrigger.params ?? {} }))
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'mainScss';
@import 'globalScss';

.AccountNavItem {
    font-size: 16px;
    font-weight: $fontWeight-defaultRegular;

    &-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;
        color: $color-lighterText;
        transition: all .3s;

        &:hover,
        &:active {
            color: $color-lightText;
        }
    }

    &-itemIcon {
        margin-right: 8px;
        font-size: 20px;
        color: $color-brandPrimary;
    }

    &--active {
        font-weight: $fontWeight-defaultBold;

        .AccountNavItem-link {
            color: $color-lightText;
        }
    }
}
</style>
