<template>
    <ul class="AccountNav">
        <li
            v-for="(group, groupIndex) in accountNav"
            :key="groupIndex"
            :class="[group.label === null ? 'AccountNav-group--withoutLabel' : '']"
            class="AccountNav-group"
        >
            {{ group.label !== null ? group.label : '' }}
            <ul class="AccountNav-list">
                <account-nav-item
                    v-for="(link, index) in group.links"
                    v-bind="link"
                    :key="index"
                    class="AccountNav-navItem"
                />
            </ul>
        </li>
    </ul>
</template>

<script>
import AccountNavItem from './AccountNavItem'

export default {
    components: {
        AccountNavItem
    },
    props: {
        accountNav: {
            type: [Array, String],
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'mainScss';
@import 'globalScss';

.AccountNav {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;

    &-group {
        padding: 25px 0 15px 0;
        border-top: 1px solid $color-lightgray3;
        font-size: 14px;
        font-weight: $fontWeight-defaultLight;
        color: $color-lightText;

        &--withoutLabel {
            padding-top: 10px;
        }

        &:first-child {
            padding-top: 0;
            border-top: none;

            .AccountNav-list {
                padding-top: 0;
            }
        }
    }

    &-groupList,
    &-list {
        margin: 0;
        padding: 6px 0 0 0;
        list-style: none;
    }

    &-item {
        margin: 0;
    }
}
</style>
