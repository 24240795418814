<template>
    <transition
        name="fade"
        @:after-leave="animationFinished"
    >
        <nav class="HeaderAccountNavContent">
            <account-nav
                :accountNav="accountNav"
                class="HeaderAccountNavContent-list"
            />
        </nav>
    </transition>
</template>

<script>
import AccountNav from './AccountNav'
import { mapState } from 'vuex'

export default {
    components: {
        AccountNav
    },
    computed: {
        ...mapState({
            accountNav: state => state.accountNav.accountNav
        })
    },
    methods: {
        animationFinished () {
            this.$emit('headerAccountNavContentOpened')
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'mainScss';
@import 'globalScss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.HeaderAccountNavContent {
    width: 325px;
    max-width: 80vw;
    padding: 12px 20px 25px;
    border-radius: 5px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, .2);
    background: $color-lightest;

    &::before {
        content: '';
        position: absolute;
        top: -9px;
        right: 10px;
        width: 0;
        height: 0;
        border-width: 0 9px 9px;
        border-style: solid;
        border-color: transparent transparent $color-lightgray2 transparent;
    }
}
</style>
