<template>
    <v-app class="AccountNavWidget">
        <header-account />
    </v-app>
</template>

<script>
import HeaderAccount from './HeaderAccount'
import * as mutationTypes from '../store/mutation-types'
import { isEmpty } from 'common/js/global-utils'
import config from 'config'
import Cookies from 'js-cookie'

export default {
    components: {
        HeaderAccount
    },
    props: {
        authenticated: {
            type: Boolean,
            default: false
        },
        checkAuthentication: {
            type: Boolean,
            default: false
        },
        domain: {
            type: String,
            default: ''
        },
        lang: {
            type: String,
            default: 'fr'
        }
    },
    async created () {
        this.setI18nLanguage(this.lang)
        this.$store.commit(`accountNav/${mutationTypes.SET_DOMAIN}`, this.domain)
        this.$store.commit(`accountNav/${mutationTypes.SET_ACCOUNT_NAV}`, config.nav)

        if (
            !isEmpty(this.checkAuthentication)
        ) {
            if (
                this.checkAuthentication === false &&
                !isEmpty(this.authenticated) &&
                this.authenticated
            ) {
                this.$store.commit(`accountNav/${mutationTypes.SET_AUTHENTICATED}`, true)
            } else if (this.checkAuthentication) {
                document.body.addEventListener('kas-logged-in', () => {
                    this.$store.commit(`accountNav/${mutationTypes.SET_AUTHENTICATED}`, true)
                })
                document.body.addEventListener('kas-logged-out', () => {
                    this.$store.commit(`accountNav/${mutationTypes.SET_AUTHENTICATED}`, false)
                })
            }
        }
    },
    mounted () {
        if (this.checkAuthentication) {
            const userToken = Cookies.get('userToken')
            if (!isEmpty(userToken)) {
                const userInfos = Cookies.get('userInfos')
                const userAccountCompleted = Cookies.get('userAccountCompleted')
                if (!isEmpty(userInfos) || !isEmpty(userAccountCompleted)) {
                    this.$store.commit(`accountNav/${mutationTypes.SET_AUTHENTICATED}`, true)
                }
            }
        }
    }
}
</script>

<style lang="scss">
.AccountNavWidget {
    .v-application--wrap {
        min-height: 0;
    }
}
</style>
